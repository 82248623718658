// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-feijoada-brasileira-index-js": () => import("./../../../src/pages/feijoada-brasileira/index.js" /* webpackChunkName: "component---src-pages-feijoada-brasileira-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lasanha-index-js": () => import("./../../../src/pages/lasanha/index.js" /* webpackChunkName: "component---src-pages-lasanha-index-js" */),
  "component---src-pages-pao-de-queijo-index-js": () => import("./../../../src/pages/pao-de-queijo/index.js" /* webpackChunkName: "component---src-pages-pao-de-queijo-index-js" */)
}

